import { config } from "react-spring/renderprops"

export function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context = this,
      args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const slideInSpringProps = (isVisible, delay = 300, right = false) => ({
  delay,
  config: config.slow,
  to: {
    opacity: isVisible ? 1 : 0,
    transform: isVisible
      ? "translateX(0)"
      : `translateX(${right ? "" : "-"}200px)`,
  },
})

export const isBrowser = typeof window !== "undefined"
