import React, {useEffect, useState, useRef} from "react"
import styled from "@emotion/styled"
import {Link} from "gatsby"
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock"
import theme from "../styles/theme";
import {debounce} from "../services/helpers";
import Logo from "../assets/v2/logo.inline.svg";
import BurgerMenu from "./burger-menu";

const VISIBILITY_SCROLL_LIMIT = 70
const MIN_SCROLL_STEP = 80

const NavLinks = ({className = "", tabIndex}) => (
    <nav className={className}>
        <StyledLink tabIndex={tabIndex} activeClassName="active" to="/">
            Valorisation
        </StyledLink>
        <StyledLink
            tabIndex={tabIndex}
            activeClassName="active"
            partiallyActive={true}
            to="https://serene-einstein-be114e.netlify.app"
        >
            L'appli
        </StyledLink>
        <StyledLink
            tabIndex={tabIndex}
            activeClassName="active"
            partiallyActive={true}
            to="/business"
        >
            Collecte
        </StyledLink>
        <StyledLink
            tabIndex={tabIndex}
            activeClassName="active"
            partiallyActive={true}
            to="/blog"
        >
            En savoir +
        </StyledLink>
    </nav>
)

const Navigation = () => {
    const [isVisible, setVisible] = useState(true)
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
    const prevScrollPos = useRef(0)
    const menuOverlayRef = useRef()

    const handleScroll = debounce(() => {
        const currentScrollPos = window.pageYOffset

        const scrollingDown = prevScrollPos.current < currentScrollPos

        if (
            isVisible &&
            currentScrollPos > VISIBILITY_SCROLL_LIMIT &&
            scrollingDown
        ) {
            setVisible(false)
        } else if (
            !isVisible &&
            !scrollingDown &&
            (prevScrollPos.current - currentScrollPos > MIN_SCROLL_STEP ||
                currentScrollPos < VISIBILITY_SCROLL_LIMIT)
        ) {
            setVisible(true)
        }

        prevScrollPos.current = currentScrollPos
    }, 30)

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [handleScroll, isVisible])

    useEffect(() => {
        return () => {
            clearAllBodyScrollLocks()
        }
    }, [])

    const toggleMenu = () => {
        const newIsOpen = !isMobileMenuOpen
        setMobileMenuOpen(newIsOpen)
        if (newIsOpen) {
            disableBodyScroll(menuOverlayRef.current)
        } else {
            enableBodyScroll(menuOverlayRef.current)
        }
    }

    return (
        <StyledHeader isVisible={isVisible}>
            <div className="inner">
                <StyledBrandLink to="/" className="h5 bold">
                    <Logo width="80" alt="WeRECY"/>
                </StyledBrandLink>
                <BurgerMenu isOpen={isMobileMenuOpen} onClick={toggleMenu}/>
                <StyledDesktopNavLinks/>
                <StyledOverlay ref={menuOverlayRef} isActive={isMobileMenuOpen}>
                    <StyledMobileNavLinks tabIndex={isMobileMenuOpen ? "0" : "-1"}/>
                </StyledOverlay>
            </div>
        </StyledHeader>
    )
}

const StyledHeader = styled.header`
  z-index: 5;
  color: ${theme.palette.text};
  position: fixed;
  left: 0;
  top: 0;
  height: ${theme.layout.headerHeight};
  background-color: ${theme.palette.background};
  transform: ${props =>
    props.isVisible ? "none" : "translate3d(0, -100%, 0)"};
  transition: transform ${theme.transition.duration.standard};

  width: 100%;
  padding: 0px;

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  nav a,
  a {
    color: ${theme.palette.text};
    text-transform: uppercase;
    font-weight: ${theme.typography.fontWeight.bold};
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }
`

const StyledBrandLink = styled(Link)`
  display: flex;
  align-items: center;
  
  svg {
    .color {
      fill: ${theme.palette.common.yellow};
      }
  }
`

const StyledLink = styled(Link)`
  position: relative;
  padding-bottom: 4px;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: ${theme.palette.common.yellow};
    visibility: hidden;
    transform: scaleX(0);
    transition: all ${theme.transition.duration.standard} ease-in-out;
  }

  &:hover:before,
  &:focus:before,
  &.active:before {
    visibility: visible;
    transform: scaleX(1);
  }

  & + a {
    margin-left: 2rem;
  }
  &[tabindex="0"] {
    outline: none;
  }
`

const StyledDesktopNavLinks = styled(NavLinks)`
  display: none;
  @media only screen and (min-width: ${theme.breakpoint.medium}px) {
    & {
      display: block;
    }
  }
`

const StyledMobileNavLinks = styled(NavLinks)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 6rem;
  height: 100%;

  & a {
    display: block;
    margin: 0 auto;
    font-size: ${theme.typography.h4};
  }

  & a + a {
    margin-top: 1rem;
  }

  @media only screen and (min-width: ${theme.breakpoint.medium}px) {
    & {
      display: none;
    }
  }
`

const StyledOverlay = styled.div`
  top: ${theme.layout.headerHeight};
  right: 0;
  width: 100vw;
  height: calc(100vh - ${theme.layout.headerHeight});
  background: ${theme.palette.primary};
  z-index: 2;
  transform: ${props =>
    props.isActive ? "translateY(0)" : "translateX(-150%)"};
  transition: transform ${theme.transition.duration.standard} ease-out;
  position: fixed;
  overflow: hidden;
`

export default Navigation
