import React from "react"
import styled from "@emotion/styled"
import theme from "../styles/theme"

const BurgerMenu = ({ isOpen = false, onClick = () => {} }) => {
  return (
    <StyledBurgerMenu
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={onClick}
      isOpen={isOpen}
    >
      <StyledBurgerLine isOpen={isOpen}></StyledBurgerLine>
      <StyledBurgerLine isOpen={isOpen}></StyledBurgerLine>
      <StyledBurgerLine isOpen={isOpen}></StyledBurgerLine>
    </StyledBurgerMenu>
  )
}

const StyledBurgerMenu = styled.div`
  display: inline-block;
  width: 25px;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }

  @media only screen and (min-width: ${theme.breakpoint.medium}px) {
    & {
      display: none;
    }
  }
`

const StyledBurgerLine = styled.div`
  height: 3px;
  margin-bottom: 3px;
  background: ${theme.palette.secondary};
  transition: transform ${theme.transition.duration.standard};

  &:last-child {
    opacity: ${props => (props.isOpen ? 0 : 1)};
  }
  &:first-of-type {
    transform: ${props =>
      props.isOpen
        ? "rotate(45deg) translate(4px, 4px)"
        : "rotate(0deg) translate(0, 0)"};
  }
  &:nth-of-type(2) {
    transform: ${props => (props.isOpen ? "rotate(-45deg)" : "rotate(0deg)")};
  }
`

export default BurgerMenu
