const colors = {
    black: "rgb(38, 38, 38)",
    gray: "rgb(129, 129, 130)",
    lightGray: "#bfbfbf",
    white: "rgb(255, 255,   255)",
    blue: "rgb(0, 207, 244)",
    darkBlue: "rgb(23, 41, 131)",
    green: "rgb(90, 206, 154)",
    pink: "rgb(228, 18, 112)",
    red: "#af0f0f",
    yellow: "#fae91a",
}

const Theme = {
    palette: {
        common: colors,
        primary: colors.blue,
        secondary: colors.pink,
        danger: colors.red,
        hightlight: [colors.pink, colors.yellow, colors.green],
        background: colors.blue,
        text: colors.white,
    },
    typography: {
        fontFamily: '"Nunito", sans-serif',
        fontSize: {
            m: "1rem",
        },
        fontWeight: {
            light: 300,
            regular: 400,
            medium: 600,
            bold: 800,
        },
        lineHeight: [1.3, 1.75],
        h1: "4rem",
        h2: "2.5rem",
        h3: "1.953rem",
        h4: "1.563rem",
        h5: "1.25rem",
        default: "1rem",
        smallText: "0.8rem",
    },
    shape: {
        borderRadius: ["15px", "20px"],
    },
    elevation: {
        level1: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
        level2: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        level3: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        level4: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    },
    layout: {
        pageWidth: 1080,
        pagePadding: "4rem",
        mobilePagePadding: "2em",
        mediumPageWidth: 600,
        headerHeight: "4rem",
        gridGap: "2rem",
        sectionGridGap: "4rem",
        borderRadius: "1rem"
    },
    breakpoint: {
        medium: 850,
        small: 400,
    },
    transition: {
        duration: {
            standard: "0.3s",
        },
    },
}

export default Theme
