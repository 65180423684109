import styled from "@emotion/styled"
import theme from "../styles/theme";
import {Link} from "gatsby";

export default styled(Link)`
    padding: 0.7rem 2rem;
    appearance: none;
    border: none;
    color: ${theme.palette.text};
    font-size: 1.5rem;
    font-weight: ${theme.typography.fontWeight.bold};
    border-radius: ${theme.shape.borderRadius[1]};
    box-shadow: ${theme.elevation.level2};
    transition: ${theme.transition.duration.standard} ease-in-out box-shadow;
    cursor: pointer;
    text-decoration: none;
    
    &:focus {
        outline: none;
    }
    
    &:hover:not([disabled]),
    &:active:not([disabled]) {
        box-shadow: ${theme.elevation.level4};
    }
    
    &[disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }
    
    &.button--small {
        padding: 0.5rem 1rem;
        font-size: 1rem;
    }
    
    &.button--blue {
        background: ${theme.palette.primary};
    }
    
    &.button--red {
        background: ${theme.palette.secondary};
    }
    
    &.button--black {
        background: ${theme.palette.common.black};
    } 
`
