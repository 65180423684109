import React from "react"
import styled from "@emotion/styled"

import Instagram from "../assets/instagram.inline.svg"
import Linkedin from "../assets/linkedin.inline.svg"

const icons = {
  instagram: {
    component: <Instagram />,
    href: "https://www.instagram.com/werecy.france/",
  },
  linkedin: {
    component: <Linkedin />,
    href: "https://www.linkedin.com/company/werecy/",
  },
}

const SocialNetworkIcon = ({ name }) => (
  <StyledLink rel="noreferrer" target="_blank" href={icons[name].href}>
    <StyledIconWrapper>{icons[name].component}</StyledIconWrapper>
  </StyledLink>
)

const StyledIconWrapper = styled.div`
  border: 2px solid white;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

const StyledLink = styled.a`
  line-height: 0;
`

export default SocialNetworkIcon
