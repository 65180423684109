import React from "react"
import {Global, css} from "@emotion/react"
import Helmet from "react-helmet"
import {ToastContainer} from "react-toastify"

import "@fontsource/nunito"
import "@fontsource/nunito/800.css"
import "@fontsource/nunito/600.css"
import "react-toastify/dist/ReactToastify.css"

import useWindowDimensions from "../hooks/useWindowDimensions.js"
import Navigation from "./navigation"
import useSiteMetadata from "../hooks/useSitemetadata"
import theme from "../styles/theme"
import "../styles/normalize.css"
import Footer from "./footer.js"

const Layout = ({children}) => {
    const {title, description} = useSiteMetadata()
    const {height, width} = useWindowDimensions()
    return (
        <div>
            <ToastContainer/>
            <Global
                styles={css`
          *,
          *::before,
          *::after {
            box-sizing: border-box;
            margin: 0;
          }

          html {
            font-size: ${theme.typography.fontSize.m};
          }

          body {
            font-family: ${theme.typography.fontFamily};
            font-weight: ${theme.typography.fontWeight.regular};
            line-height: ${theme.typography.lineHeight[1]};
            /* remove animations on scroll if js is disabled */
            &.no-js .reveal {
              opacity: 1 !important;
              transform: none !important;
            }
            &:not(.no-js) .no-js {
              display: none;
            }
          }

          main {
            padding-bottom: 3rem;
            overflow: hidden;
          }

          section,
          footer,
          header {
            padding: ${theme.layout.mobilePagePadding} 0;
            @media only screen and (min-width: ${theme.breakpoint.medium}px) {
              padding: ${theme.layout.pagePadding} 0;
            }
            
            /* keep header style in Navigation component synchronised */
            .inner {
              margin: 0 auto;
              width: 90%;
              max-width: ${theme.layout.pageWidth}px;
              @media only screen and (min-width: ${theme.breakpoint.medium}px) {
                width: ${theme.layout.pageWidth}px;
              }
            }
          }

          .section-full {
            min-height: ${width > height && height < 1000
                    ? height + "px"
                    : "auto"};
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          em {
            color: ${theme.palette.hightlight[0]};
            font-style: normal;
          }

          em.variant {
            color: ${theme.palette.hightlight[1]};
          }

          em.variant2 {
            color: ${theme.palette.hightlight[2]};
          }

          h1,
          h2,
          h3,
          h4,
          h5 {
            /* word-wrap: break-word; */
            text-transform: uppercase;
            font-weight: 900;
            line-height: ${theme.typography.lineHeight[0]};
            margin: 0;
          }
          h1,
          .h1 {
            font-size: ${theme.typography.h3};
            @media only screen and (min-width: ${theme.breakpoint.small}px) {
              font-size: ${theme.typography.h1};
            }
          }

          h2,
          .h2 {
            font-size: ${theme.typography.h4};
            @media only screen and (min-width: ${theme.breakpoint.small}px) {
              font-size: ${theme.typography.h2};
            }
          }

          h3,
          .h3 {
            font-size: ${theme.typography.h5};
            margin-bottom: 3rem;
            @media only screen and (min-width: ${theme.breakpoint.small}px) {
              font-size: ${theme.typography.h3};
            }
          }

          h4,
          .h4 {
            font-size: 1em;
            @media only screen and (min-width: ${theme.breakpoint.small}px) {
              font-size: ${theme.typography.h4};
            }
          }

          h5,
          .h5 {
            font-size: 1em;
            @media only screen and (min-width: ${theme.breakpoint.small}px) {
              font-size: ${theme.typography.h5};
            }
          }

          p {
            font-size: 1.2rem;
            font-weight: ${theme.typography.fontWeight.medium};
            line-height: 1.4em;
            color: ${theme.palette.common.black};
            max-width: 40em;
            margin-top: 0;
          }

          small,
          .text_small {
            font-size: ${theme.typography.smallText};
          }

          /* input */
          input,
          textarea {
            width: 100%;
            color: ${theme.palette.common.black};
            padding: 12px 18px;
            border: none;
            border-radius: ${theme.shape.borderRadius[1]};
            transition: ${theme.transition.duration.standard} ease-in-out
              box-shadow;
          }

          input:focus,
          textarea:focus {
            outline: none;
            box-shadow: 0 0 0 1px ${theme.palette.common.darkBlue};
          }

          input.error,
          textarea.error {
            outline: none;
            box-shadow: 0 0 0 1px ${theme.palette.danger};
          }

          textarea {
            height: 8rem;
            border-radius: ${theme.shape.borderRadius[0]};
          }

          ::placeholder {
            color: ${theme.palette.common.gray};
          }


          /* layout */

          .grid {
            --gridCols: 2;
            --elem: 1fr;
            display: block;
            grid-template-columns: repeat(var(--gridCols), var(--elem));
            align-content: center;
            align-items: center;
            grid-gap: ${theme.layout.gridGap};
          }

          .grid-section {
            grid-gap: ${theme.layout.sectionGridGap};
          }

          .centered-grid-element {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 2rem;
          }

          .centered-grid-element:last-child {
            margin-bottom: 0;
          }

          @media only screen and (min-width: ${theme.breakpoint.medium}px) {
            .grid {
              display: grid;
            }
            .centered-grid-element {
              margin-bottom: 0;
            }
          }

          .grid_3-2 {
            --gridCols: 1;
            --elem: 3fr 2fr;
          }

          .grid_2-3 {
            --gridCols: 1;
            --elem: 2fr 5fr;
          }

          .cols-3 {
            --gridCols: 3;
          }

          .col-last {
            order: 1;
          }

          /* utils */

          .margin-bottom {
            margin-bottom: 2rem;
          }

          .margin-bottom-sm {
            margin-bottom: 1rem;
          }

          @media only screen and (min-width: ${theme.breakpoint.medium}px) {
            .margin-bottom-sm {
              margin-bottom: 0;
            }
          }

          .marginless {
            margin-bottom: 0;
            margin-top: 0;
            margin-left: 0;
            margin-right: 0;
          }

          .dark {
            color: ${theme.palette.common.black};
            font-weight: ${theme.typography.fontWeight.bold};
            text-transform: uppercase;
          }

          .bold {
            font-weight: ${theme.typography.fontWeight.bold};
          }

          .medium {
            font-weight: ${theme.typography.fontWeight.medium};
          }

          .dense {
            line-height: ${theme.typography.lineHeight[0]};
          }

          .text-center {
            text-align: center;
          }

          .text-sm-center {
            text-align: center;
          }

          @media only screen and (min-width: ${theme.breakpoint.medium}px) {
            .text-sm-center {
              text-align: left;
            }
          }

          .text-left {
            text-align: left;
          }

          .bg-white {
            background-color: #f7f7f7;
          }

          /*  Toastify */
          .Toastify__toast--success {
            background-color: ${theme.palette.common.darkBlue};
          }

          .Toastify__toast--error {
            background-color: ${theme.palette.danger};
          }

          button.Toastify__close-button {
            width: auto;
            box-shadow: none;
          }

          .blog {
            max-width: 40em;
          }
        `}
            />
            <Helmet>
                <html lang="fr"/>
                <title>{title}</title>
                <meta name="description" content={description}/>
            </Helmet>
            <Navigation/>
            <main>{children}</main>
            <Footer/>
        </div>
    )
}

export default Layout
