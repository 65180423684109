import React from "react";
import styled from "@emotion/styled";
import {StaticImage} from "gatsby-plugin-image";

import Downloads from "./downloads";
import SocialNetworkIcon from "./social-network-icon";
import Logo from "../assets/v2/logo.inline.svg";
import theme from "../styles/theme";
import Button from "./button";

const Footer = () => (
    <StyledFooter>
        <div className="inner">
            <h2>Besoin de revaloriser ?</h2>

            <StyledBackgroundLines>
                <Button className={'button--red'} href={"mailto:leo@werecy.com"}>Let's GO</Button>
            </StyledBackgroundLines>

            <StyledLogos>
                <StaticImage
                    src='../images/v2/logo-veolia.png'
                    alt='Logo Véolia'
                    fit={'inside'}
                    height={80}
                ></StaticImage>
                <StaticImage
                    src='../images/v2/logo-bpi.png'
                    alt='Logo BPI'
                    fit={'inside'}
                    height={80}
                ></StaticImage>
                <StaticImage
                    src='../images/v2/logo-ademe.png'
                    alt='Logo Ademe'
                    fit={'inside'}
                    height={80}
                ></StaticImage>
                <StaticImage
                    src='../images/v2/logo-region-sud.jpeg'
                    alt='Logo Région Sud'
                    fit={'inside'}
                    height={80}
                ></StaticImage>
                <StaticImage
                    src='../images/v2/logo-french-tech.png'
                    alt='Logo French Tech'
                    fit={'inside'}
                    height={80}
                ></StaticImage>
                <StaticImage
                    src='../images/v2/logo-coq-vert.png'
                    alt='Logo Coq Vert'
                    fit={'inside'}
                    height={80}
                ></StaticImage>
            </StyledLogos>

            <StyledCopyright>
                <StyledLogoWrapper>
                    <Logo width="75" alt="Logo WeRECY"/>
                </StyledLogoWrapper>
                <SocialNetworkIconsWrapper>
                    <SocialNetworkIcon name="instagram"/>
                    <SocialNetworkIcon name="linkedin"/>
                </SocialNetworkIconsWrapper>
                <p>
                    Copyright © 2022 Werecy All Rights Reserved<br/>
                    <a href={"./politique_de_confidentialite_werecy.pdf"} target={"_blank"} rel={"noreferrer"}>Lien de confidentialité</a>
                </p>
                <Downloads/>
            </StyledCopyright>
        </div>
    </StyledFooter>
)

const StyledFooter = styled.footer`
    background: ${theme.palette.primary};
    padding-bottom: calc(${theme.layout.sectionGridGap} * 2);
    
    & > .inner {
        display: flex;
        flex-direction: column;
        gap: ${theme.layout.gridGap};
        align-items: center;
    }

    h2 {
        text-align: center;
    }
`

const StyledLogos = styled.div`
    background: ${theme.palette.text};
    padding: 2rem;
    border-radius: ${theme.layout.borderRadius};
    display: flex;
    flex-direction: column;
    gap: ${theme.layout.gridGap};
    align-items: center;
    margin: ${theme.layout.sectionGridGap} auto;
    
    @media only screen and (min-width: ${theme.breakpoint.medium}px) {
        flex-direction: row;
    }
`

const StyledLogoWrapper = styled.div`
`

const SocialNetworkIconsWrapper = styled.div`
    display: flex;
    gap: calc(${theme.layout.gridGap} / 2);
    align-items: center;
`

const StyledCopyright = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.layout.gridGap};
    justify-content: space-between;
    width: 100%;
    align-items: center;
    
    @media only screen and (min-width: ${theme.breakpoint.medium}px) {
        flex-direction: row;
    }
    
    & > p {
        font-size: 1rem;
        color: ${theme.palette.text};
        text-align: center;
        
        a {
            color: ${theme.palette.text};
        }
    }
`

const StyledBackgroundLines = styled.div`
    background: url('background-lines-pink.svg') no-repeat top right;
    background-size: 50px 50px;
    padding: 40px 40px 0px;
`

export default Footer
