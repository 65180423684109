import { useState, useEffect } from "react"
import { isBrowser } from "../services/helpers"
import theme from "../styles/theme"

function getWindowDimensions() {
  if (isBrowser) {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
      isMobile: width < theme.breakpoint.medium,
    }
  } else {
    return {
      width: undefined,
      height: undefined,
      isMobile: undefined,
    }
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}
