import React from "react"
import styled from "@emotion/styled"
import AppStore from "../assets/app-store.inline.svg"
import GooglePlay from "../assets/google-play.inline.svg"

const Downloads = () => (
  <StyledWrapper>
    <StyledLink
      rel="noreferrer"
      target="_blank"
      href="https://apps.apple.com/fr/app/werecy-recycler-cest-gagner/id1522054035"
    >
      <AppStore width={110} />
    </StyledLink>
    <StyledLink
      rel="noreferrer"
      target="_blank"
      href="https://play.google.com/store/apps/details?id=com.werecy"
    >
      <GooglePlay width={110} />
    </StyledLink>
  </StyledWrapper>
)

const StyledLink = styled.a`
  line-height: 0;
`

const StyledWrapper = styled.div`
  display: flex;
  width: 240px;
  justify-content: space-between;
`

export default Downloads
